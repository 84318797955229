.segment:hover {
	background-color: gold;
}

.missing-id {
	background-color: rgba(255,127,127,0.3);
}

.missing-id:hover {
	background-color: rgb(255,127,127);
}

.not-translated {
	background-color: rgba(127,127,255,0.3);
}

.not-translated:hover {
	background-color: rgb(127,127,255);
}

.error {
	background-color: rgb(255,127,127);
}
