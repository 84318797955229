@value gutter from "theme.module.css";

.row {
	height: 40px;
	padding: 0 gutter;
	line-height: 40px;
}

.row + .row {
	height: 41px;
	border-style: solid;
	border-color: rgba(0, 0, 0, .1);
	border-width: 1px 0 0;
}

.meta {
	display: flex;
}
