@font-face {
	font-family: 'job-icons';
	src:  url('./job-icons.eot?hnrnv1');
	src:  url('./job-icons.eot?hnrnv1#iefix') format('embedded-opentype'),
		url('./job-icons.ttf?hnrnv1') format('truetype'),
		url('./job-icons.woff?hnrnv1') format('woff'),
		url('./job-icons.svg?hnrnv1#job-icons') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

.icon {
	margin-right: .2em;
	vertical-align: middle;

	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'job-icons', sans-serif !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Enable Ligatures */
	letter-spacing: 0;
	-webkit-font-feature-settings: "liga";
	-moz-font-feature-settings: "liga=1";
	-moz-font-feature-settings: "liga";
	-ms-font-feature-settings: "liga" 1;
	font-feature-settings: "liga";
	-webkit-font-variant-ligatures: discretionary-ligatures;
	font-variant-ligatures: discretionary-ligatures;

	/* Better Font Rendering */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
