@value gutter, md from 'theme.module.css';

.container {
	position: relative;
}

@media md {
	.container {
		margin: gutter;
	}
}
