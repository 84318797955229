@value gutter from "theme.module.css";

.link {
	margin: 0 calc(-1 * gutter);
	padding: 5px gutter;
	cursor: pointer;
}
.link:first-of-type {
	margin-top: gutter;
}
.link:last-of-type {
	margin-bottom: calc(gutter + 40px);
}

.active, .link:hover {
	font-weight: bold;
}

.active {
	/* TODO: Themable? */
	background: #e1e4e6;
}
