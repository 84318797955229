@value gutter, themeBackground from "theme.module.css";

/* Consolidated handling for vertical spacing between content blocks */
.verticalSpacing {
	margin: gutter 0;
}
.verticalSpacing:first-child {
	margin-top: 0;
}
.verticalSpacing:last-child {
	margin-bottom: 0;
}

/* Page building block */
.block {
	composes: verticalSpacing;
	background: themeBackground;
}
